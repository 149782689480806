import React from "react"

import Layout from "../components/layout-info"
import SEO from "../components/seo"
import API, { graphqlOperation } from '@aws-amplify/api';
import config from '../aws-exports';



class NotFoundPage extends React.Component {


  registerUnknownPage = (url) => {
    var logger = _LTracker || [];
    logger.push("404 found: " + url);
    
    const statement = `mutation CreatePageNotFound($input: CreatePageNotFoundInput!, $condition: ModelPageNotFoundConditionInput) {
      createPageNotFound(input: $input, condition: $condition) {
        url        
      }
    }`;


    API.graphql(graphqlOperation(statement, { input: {url: url} , condition:null }))
      .then(result => {
        // ok
      })
      .catch(e => {
          logger.push("Failed to log 404 found error ");
          logger.push(e);
      });

  }



  componentDidMount() {
      console.log('unknown page');
      API.configure(config);
      this.registerUnknownPage(window.location.href);
   }

  render() {

    return (
      <Layout>
        <SEO title="404: Ikke funnet" />
        <h1>Siden finnes ikke</h1>
        <p>Siden du leter etter er enten flyttet eller eksisterer ikke lenger.</p>
        <p>Gå til <a href="/"> forsiden</a>.</p>
        <p>Dersom vi har linket feil setter vi pris på om du <a href="mailto:kundeservice@leirskoledagboken.no"> sender oss en tilbakemelding</a>.</p>
      </Layout>
    )
  }
}

export default NotFoundPage
